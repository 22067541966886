import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Fractals in React",
  "description": "I spent 3 hours building a Pythagoras tree fractal. It's 2:30am, and is my life even real? Who the hell accidentally spends all night building fractals? Me… I guess.",
  "date": "2016-11-18T08:00:00.000Z",
  "published": "2016-11-18T08:00:00.000Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`So… this started as an article about why recursion doesn`}{`'`}{`t work in React. `}<a parentName="p" {...{
        "href": "https://swizec.com/blog/simple-mobx-driven-modals/swizec/7166"
      }}>{`It `}<em parentName="a">{`looks`}</em>{` like it works`}</a>{`, then you `}<inlineCode parentName="p">{`npm run build`}</inlineCode>{`, and it stops working.`}</p>
    <p>{`Curious, right? Worth looking into, eh?`}</p>
    <p>{`That`}{`'`}{`s not the article you`}{`'`}{`re getting. It `}<em parentName="p">{`started`}</em>{` as that article, then I spent 3 hours building a Pythagoras tree fractal. It`}{`'`}{`s 2:30am, and is my life even real?`}</p>
    <p>{`Who the hell accidentally spends all night building fractals? Me… I guess.`}</p>
    <p><img parentName="p" {...{
        "src": "/0d9aee144ba12aae82007c2d18e299ea/blog-wp-content-uploads-2016-11-growing-pythagoras-tree.gif",
        "alt": null
      }}></img></p>
    <p>{`Pretty, innit? Built with React, and it`}{`'`}{`s going to stop working when I `}<inlineCode parentName="p">{`npm run build`}</inlineCode>{`. Still don`}{`'`}{`t know why. I`}{`'`}{`ll figure `}<em parentName="p">{`that`}</em>{` out next week.`}</p>
    <p>{`Here`}{`'`}{`s how the `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Pythagoras_tree_(fractal)"
      }}>{`Pythagoras tree`}</a>{` works:`}</p>
    <blockquote>
      <p parentName="blockquote">{`The construction of the Pythagoras tree begins with a square. Upon this square are constructed two squares, each scaled down by a linear factor of ½√2, such that the corners of the squares coincide pairwise. The same procedure is then applied recursively to the two smaller squares, ad infinitum.`}</p>
    </blockquote>
    <p>{`?`}</p>
    <p>{`That becomes four bullet points:`}</p>
    <ul>
      <li parentName="ul">{`1 component called `}<inlineCode parentName="li">{`<Pythagoras >`}</inlineCode></li>
      <li parentName="ul">{`draws rectangle`}</li>
      <li parentName="ul">{`calculates props for next 2 rectangles`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`<Pythagoras><Pythagoras>`}</inlineCode></li>
    </ul>
    <p>{`Which turns into some 30 lines of code:`}</p>
    <pre><code parentName="pre" {...{}}>{`\\import React from 'react';
\\import { interpolateViridis } from 'd3-scale';

const Factor = .5*Math.sqrt(2);

const Pythagoras = ({ maxlvl, w, x, y, lvl, left, right }) => {
    if (lvl > maxlvl || w < 1) {
        return null;
    }

    const nextLeft = Factor*w,
          nextRight = Factor*w,
          d = nextLeft + nextRight + w,
          A = 45,
          B = 45;

    let rotate = '';

    if (left) {
        rotate = \`rotate(\${-A} 0 \${w})\`;
    }else if (right) {
        rotate = \`rotate(\${B} \${w} \${w})\`;
    }

    return (
        
            

            

            
        
    );
};


export default Pythagoras;
`}</code></pre>
    <p>{`Beautiful. Let me explain.`}</p>
    <p><inlineCode parentName="p">{`interpolateViridis`}</inlineCode>{` is a `}<a parentName="p" {...{
        "href": "https://github.com/d3/d3-scale"
      }}>{`d3-scale`}</a>{` that gives beautiful colors. Call it with an argument in `}<inlineCode parentName="p">{`[0, 1]`}</inlineCode>{` and it returns a color.`}</p>
    <p><inlineCode parentName="p">{`Factor`}</inlineCode>{` is the constant linear factor. We use it to calculate the sides of future rectangles.`}</p>
    <p><inlineCode parentName="p">{`d`}</inlineCode>{` is the diameter of the triangle formed by the current square and two future squares. More on that later.`}</p>
    <p><inlineCode parentName="p">{`A`}</inlineCode>{` and `}<inlineCode parentName="p">{`B`}</inlineCode>{` are angles for each future rectangle. Set to 45 degrees statically.`}</p>
    <h2 {...{
      "id": "then-we-start-drawing"
    }}>{`Then we start drawing.`}</h2>
    <p>{`If we`}{`'`}{`re in a `}<inlineCode parentName="p">{`left`}</inlineCode>{` rectangle, we set up a left rotation; if `}<inlineCode parentName="p">{`right`}</inlineCode>{` then a right rotation. `}<inlineCode parentName="p">{`rotate()`}</inlineCode>{` is an SVG transformation that rotates the current coordinate system.`}</p>
    <p>{`To draw the rectangle, we:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`translate`}</inlineCode>{` to `}<inlineCode parentName="li">{`(x, y`}</inlineCode>{`), that means `}{`"`}{`move there`}{`"`}</li>
      <li parentName="ul">{`add the rotation`}</li>
      <li parentName="ul">{`now our coordinate system is moved and rotate`}</li>
      <li parentName="ul">{`draw a rectangle at `}<inlineCode parentName="li">{`(0, 0)`}</inlineCode></li>
      <li parentName="ul">{`add two `}<inlineCode parentName="li">{`<Pythagoras>`}</inlineCode>{` with new parameters`}</li>
    </ul>
    <p>{`And that`}{`'`}{`s how you build a fractal in React. It won`}{`'`}{`t work in production, but it sure looks pretty on your localhost.`}</p>
    <p><img parentName="p" {...{
        "src": "/0d9aee144ba12aae82007c2d18e299ea/blog-wp-content-uploads-2016-11-growing-pythagoras-tree-1.gif",
        "alt": null
      }}></img></p>
    <p>{`The animation is done in `}<a parentName="p" {...{
        "href": "https://github.com/Swizec/react-fractals/blob/master/src/App.js"
      }}>{`App.js`}</a>{` with a timer that updates the `}<inlineCode parentName="p">{`maxlvl`}</inlineCode>{` prop every 500ms. Calling the root node of `}<inlineCode parentName="p">{`Pythagoras`}</inlineCode>{` looks like this:`}</p>
    <pre><code parentName="pre" {...{}}>{`   
`}</code></pre>
    <p>{`Start `}<inlineCode parentName="p">{`lvl`}</inlineCode>{` at `}<inlineCode parentName="p">{`0`}</inlineCode>{` and set the `}<inlineCode parentName="p">{`maxlvl`}</inlineCode>{`. Those are `}{`\\`}{`important. At `}<inlineCode parentName="p">{`maxlvl`}</inlineCode>{` past 12 or 13, it stops working. It takes too much CPU power to ever render.`}</p>
    <p>{`Yes, I tried. The naive algorithm isn`}{`'`}{`t good enough. You could optimize by taking calculations out of recursion and preparing them in advance.`}</p>
    <h2 {...{
      "id": "the-part-i-cant-figure-out"
    }}>{`The part I can`}{`'`}{`t figure out`}</h2>
    <p>{`Look at `}<a parentName="p" {...{
        "href": "http://andrew-hoyer.com/experiments/fractals/"
      }}>{`Andrew Hoyer`}{`'`}{`s`}</a>{` Pythagoras tree. That thing is beautiful and flexible and dances like tree-shaped worm.`}</p>
    <p><img parentName="p" {...{
        "src": "/12145d7526c48c2e3db809eb277f006b/blog-wp-content-uploads-2016-11-andrew-hoyer-tree.gif",
        "alt": null
      }}></img></p>
    <p>{`?`}</p>
    <p>{`I can`}{`'`}{`t figure out how to calculate those angles and rectangle sizes. I know that using `}<inlineCode parentName="p">{`.5`}</inlineCode>{` in the `}<inlineCode parentName="p">{`Factor`}</inlineCode>{` is for `}<inlineCode parentName="p">{`45`}</inlineCode>{` degree angles.`}</p>
    <p>{`You can change the ratio by using a `}<inlineCode parentName="p">{`.3`}</inlineCode>{` and `}<inlineCode parentName="p">{`.7`}</inlineCode>{` factor for each side. Then it stops working with `}<inlineCode parentName="p">{`45`}</inlineCode>{` degree angles yeah.`}</p>
    <p><img parentName="p" {...{
        "src": "/309f6decb4e74efe615b7ba54abad077/blog-wp-content-uploads-2016-11-broken-tree.gif",
        "alt": null
      }}></img></p>
    <p>{`Ok, that was expected. Since you know all the sides, you should be able to apply the `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Law_of_sines"
      }}>{`Law of Sines`}</a>{` to calculate the angle.`}</p>
    <pre><code parentName="pre" {...{}}>{` const nextLeft = .3*Factor*w,
          nextRight = .7*Factor*w,
          d = nextLeft + nextRight + w,
          A = Math.degrees(Math.asin(nextRight/d)),
          B = Math.degrees(Math.asin(nextLeft/d));
`}</code></pre>
    <p><img parentName="p" {...{
        "src": "/7a16a4bab8746d5e2957b937dfe4aa84/blog-wp-content-uploads-2016-11-broken-tree2.gif",
        "alt": null
      }}></img></p>
    <p>{`I can`}{`'`}{`t figure it out. I`}{`'`}{`m pretty sure I`}{`'`}{`m applying the Law of Sines correctly, but the numbers it throws out are wrong.`}</p>
    <p>{`Halp ?`}</p>
    <p>{`PS: Here`}{`'`}{`s a paper that describes `}<a parentName="p" {...{
        "href": "https://pdfs.semanticscholar.org/08dd/831514db573cb9fd2b993a5814007888117a.pdf"
      }}>{`using Pythagoras trees as data structures`}</a>{`. Sort of.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      